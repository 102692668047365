.logoWrapper {
    position: relative;
    display: block;
    height: 20em;
}

#logo {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: fit-content;
    animation: fill-logo 2s ease forwards 1.2s;
}

#logo-strapline {
    position: absolute;
    top: calc(45% + 90px);
    left: 50%;
    transform: translateX(-49%);
    min-width: fit-content;
    animation: move-logo-strapline 2s ease forwards, fill-logo-strapline 2s ease forwards 0.6s;
}

#logo path:nth-child(1) {
    stroke-dasharray: 342.8843994140625;
    stroke-dashoffset: 342.8843994140625;
    animation: line-animation 3s ease forwards 0.04s;
}
#logo path:nth-child(2) {
    stroke-dasharray: 250.220458984375;
    stroke-dashoffset: 250.220458984375;
    animation: line-animation 3s ease forwards 0.08s;
}
#logo path:nth-child(3) {
    stroke-dasharray: 291.34552001953125;
    stroke-dashoffset: 291.34552001953125;
    animation: line-animation 3s ease forwards 0.12s;
}
#logo path:nth-child(4) {
    stroke-dasharray: 200.33944702148438;
    stroke-dashoffset: 200.33944702148438;
    animation: line-animation 3s ease forwards 0.16s;
}
#logo path:nth-child(5) {
    stroke-dasharray: 321.62200927734375;
    stroke-dashoffset: 321.62200927734375;
    animation: line-animation 3s ease forwards 0.2s;
}
#logo path:nth-child(6) {
    stroke-dasharray: 276.42669677734375;
    stroke-dashoffset: 276.42669677734375;
    animation: line-animation 3s ease forwards 0.24s;
}
#logo path:nth-child(7) {
    stroke-dasharray: 398.1600341796875;
    stroke-dashoffset: 398.1600341796875;
    animation: line-animation 3s ease forwards 0.28s;
}
#logo path:nth-child(8) {
    stroke-dasharray: 316.8760681152344;
    stroke-dashoffset: 316.8760681152344;
    animation: line-animation 3s ease forwards 0.32s;
}
#logo path:nth-child(9) {
    stroke-dasharray: 373.4826965332031;
    stroke-dashoffset: 373.4826965332031;
    animation: line-animation 3s ease forwards 0.36s;
}
#logo-strapline path:nth-child(1) {
    stroke-dasharray: 94.5604476928711;
    stroke-dashoffset: 94.5604476928711;
    animation: line-animation 2s ease forwards 0.04s;
}
#logo-strapline path:nth-child(2) {
    stroke-dasharray: 130.72726440429688;
    stroke-dashoffset: 130.72726440429688;
    animation: line-animation 2s ease forwards 0.08s;
}
#logo-strapline path:nth-child(3) {
    stroke-dasharray: 114.78340911865234;
    stroke-dashoffset: 114.78340911865234;
    animation: line-animation 2s ease forwards 0.12s;
}
#logo-strapline path:nth-child(4) {
    stroke-dasharray: 130.727294921875;
    stroke-dashoffset: 130.727294921875;
    animation: line-animation 2s ease forwards 0.16s;
}
#logo-strapline path:nth-child(5) {
    stroke-dasharray: 102.65223693847656;
    stroke-dashoffset: 102.65223693847656;
    animation: line-animation 2s ease forwards 0.2s;
}
#logo-strapline path:nth-child(6) {
    stroke-dasharray: 176.33352661132812;
    stroke-dashoffset: 176.33352661132812;
    animation: line-animation 2s ease forwards 0.24s;
}
#logo-strapline path:nth-child(7) {
    stroke-dasharray: 114.78350067138672;
    stroke-dashoffset: 114.78350067138672;
    animation: line-animation 2s ease forwards 0.28s;
}
#logo-strapline path:nth-child(8) {
    stroke-dasharray: 102.65228271484375;
    stroke-dashoffset: 102.65228271484375;
    animation: line-animation 2s ease forwards 0.32s;
}
#logo-strapline path:nth-child(9) {
    stroke-dasharray: 101.00760650634766;
    stroke-dashoffset: 101.00760650634766;
    animation: line-animation 2s ease forwards 0.36s;
}
#logo-strapline path:nth-child(10) {
    stroke-dasharray: 82.9890365600586;
    stroke-dashoffset: 82.9890365600586;
    animation: line-animation 2s ease forwards 0.4s;
}
#logo-strapline path:nth-child(11) {
    stroke-dasharray: 102.6524887084961;
    stroke-dashoffset: 102.6524887084961;
    animation: line-animation 2s ease forwards 0.44s;
}
#logo-strapline path:nth-child(12) {
    stroke-dasharray: 91.65288543701172;
    stroke-dashoffset: 91.65288543701172;
    animation: line-animation 2s ease forwards 0.48s;
}
#logo-strapline path:nth-child(13) {
    stroke-dasharray: 120.36894226074219;
    stroke-dashoffset: 120.36894226074219;
    animation: line-animation 2s ease forwards 0.52s;
}
#logo-strapline path:nth-child(14) {
    stroke-dasharray: 94.56056213378906;
    stroke-dashoffset: 94.56056213378906;
    animation: line-animation 2s ease forwards 0.56s;
}
#logo-strapline path:nth-child(15) {
    stroke-dasharray: 90.306884765625;
    stroke-dashoffset: 90.306884765625;
    animation: line-animation 2s ease forwards 0.6s;
}
#logo-strapline path:nth-child(16) {
    stroke-dasharray: 102.65270233154297;
    stroke-dashoffset: 102.65270233154297;
    animation: line-animation 2s ease forwards 0.64s;
}
#logo-strapline path:nth-child(17) {
    stroke-dasharray: 119.41326904296875;
    stroke-dashoffset: 119.41326904296875;
    animation: line-animation 2s ease forwards 0.68s;
}
#logo-strapline path:nth-child(18) {
    stroke-dasharray: 104.39189147949219;
    stroke-dashoffset: 104.39189147949219;
    animation: line-animation 2s ease forwards 0.72s;
}

/* #cssGen {
    height: 100%;
    width: 100%;
} */
@keyframes line-animation {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes fill-logo {
    from {
        fill: transparent;
    }
    to {
        fill: #f06c00;
    }
}

@keyframes fill-logo-strapline {
    from {
        fill: transparent;
    }
    to {
        fill: #4c4c4c;
    }
}

@keyframes move-logo-strapline {
    to {
        top: calc(45% + 60px);
    }
}
