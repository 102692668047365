.countParent {
    position: relative;
}

.count {
    font-weight: 700;
    min-height: 48px;
    position: absolute;
    top: -3rem;
}

.clearButton {
    vertical-align: middle;
    margin-left: 1rem;
}