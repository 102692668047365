$ln-assets-url: "~luna-style";
$ln-fonts-url: "../../fonts/";
@import '@jsluna/style/scss/main';

$colour: #f2f2f2 !important;

body {
    background-color: $colour;
}

[tabindex]:focus,
a:focus,
button:focus {
    box-shadow: none !important;
    outline: none;
}

[tabindex]:active,
a:active,
button:active {
    border-style: none;
}

ul:hover,
li:hover {
    cursor: pointer;
}

.ln-c-button--icon {
    height: 48px;
}

.ln-c-list-group__item .ln-c-form-group {
    margin-bottom: 0rem;
    position: relative;
}

.customGrowl {
    opacity: 1 !important;
}

.DateInput_input {
    width: 130px;
}

.smallDate .DateInput_input,
.smallDate .DateInput,
.smallDate .dateInput,
.smallDate .SingleDatePicker,
.smallDate .SingleDatePickerInput {
    width: 100px;
}

.smallDate .DateInput_input {
    padding: 0 5px;
}
// .dateList,
// .smallDate {
// 	pointer-events: none;
// }
// .dateInput input {
// 	pointer-events: initial !important;
// }

.weekNumberLi {
    cursor: default;
    user-select: none;
}
.weekNumberLi:hover {
    cursor: pointer;
}
.dateList .ln-c-list-group__item {
    margin-top: -1px !important;
    padding: 0;
}
.dateList .ln-c-list-group__item.weekNumberLi {
    margin-top: 0px !important;
    padding: 0;
}
.ln-o-grid .dateList .ln-c-list-group__item.weekNumberLi {
    margin-top: 0px !important;
    margin-left: -2px;
}
.ln-o-grid .ln-c-form-group .dateList .ln-c-list-group__item.weekNumberLi {
    margin-top: 0px !important;
    margin-left: -1px;
}
.ln-o-grid .ln-c-list-group .dateList .ln-c-list-group__item.weekNumberLi {
    margin-top: -1px !important;
    margin-left: -1px;
}
// .ln-c-card .ln-c-form-group .dateList .ln-c-list-group__item.weekNumberLi,
.ln-c-modal .ln-c-form-group .dateList .ln-c-list-group__item.weekNumberLi {
    margin-top: -0px !important;
    margin-left: -1px;
}
.ln-c-accordion .dateList .ln-c-list-group__item.weekNumberLi {
    margin-top: 0px !important;
    margin-left: -2px;
}

.ln-c-modal .dateList .weekNumberLi {
    margin-top: 1px !important;
    margin-left: -1px;
}
.ln-c-accordion__body .weekNumberLi {
    margin-top: 1px !important;
    margin-left: -2px;
}
.weekNumber {
    // position: absolute;
    // z-index: 1;
    height: 48px;
    border: 1px solid #e55000;
    background-color: #f06c00;
    color: white;
    border-radius: 2px;
    // left: 131px;
    padding: 16px 11px 8px;
    // vertical-align: inherit;
    font-weight: bold;
    top: 1px; //32.2px;
    min-width: 48px;
    text-align: center;
    font-size: 20px;
}
.weekNumber::before {
    content: "Week";
    font-size: 12px;
    position: absolute;
    display: table;
    float: left;
    top: 2px;
    left: 0;
    text-align: center;
    white-space: nowrap;
    width: 100%;
}
.weekNumber {
    pointer-events: none;
}

.mini-button {
    width: 22.5px !important;
    height: 22.5px !important;
    min-width: initial !important;
    min-height: initial !important;
    border: none !important;
    font-size: 1.35rem !important;
    margin-left: 10px !important;
    margin-top: 0.5px !important;
}

div.devLabel {
    display: none;
}

.ln-o-page__header {
    background-color: white;
}

.ln-c-header > .ln-o-container {
    background-color: transparent !important;
}

.DEV .ln-c-header,
.DEV .ln-c-header > .ln-o-container,
.UAT .ln-c-header,
.UAT .ln-c-header > .ln-o-container,
.LOCAL .ln-c-header,
.LOCAL .ln-c-header > .ln-o-container {
    background-image: none !important;
    background-color: transparent !important;
}

.DEV div.devLabel,
.LOCAL div.devLabel,
.UAT div.devLabel {
    display: block;
    position: fixed;
    bottom: 15px;
    right: -50px;
    transform: rotate(-45deg);
    padding: 6px 0;
    font-size: 1.5em;
    width: 180px;
    text-align: center;
    color: white;
    z-index: 1;
    pointer-events: none;
}

// .DEV .ln-c-header {
// 	background-color: rgba(120, 120, 120, 0.5) !important;
// }

.DEV div.devLabel {
    background: rgba(120, 120, 120, 0.5) !important;
}

// .LOCAL .ln-c-header {
// 	background-color: rgba(70, 180, 255, 0.5) !important;
// }

.LOCAL div.devLabel {
    background: rgba(70, 180, 255, 0.7) !important;
}

.UAT div.devLabel {
    background: rgba(255, 129, 129, 0.767) !important;
}

.p-button {
    border-color: $ln-color-orange !important;
    margin-left: 1rem !important;
}
.buttonActive.p-button,
.buttonActive {
    background-color: $ln-color-orange !important;
    color: $ln-color-white !important;
    border-color: $ln-color-orange !important;
}

.notificationCounter {
    background-color: $ln-color-red;
    position: absolute;
    height: 26px;
    width: 26px;
    top: 4px;
    border-radius: 20px;
    color: white;
    line-height: 20px;
    right: 0px;
    font-size: 13px;
    border: 2px solid white;
}

.scheduleRouteModal .ln-c-modal__body{
    max-width: 75%;
}
