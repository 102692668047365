.gap {
    background-color: grey;
    display: inline-block;
    width: 3px;
    padding: 0px !important;
}

.viewToSmall {
    display: none !important;
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.noData {
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

:global .noWrapElipses .p-panel-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}

.dcCycleNo {
    font-weight: bold;
}

.windowAdjusted {
    background-color: #ffc911;
    color: black;
    // border-radius: 20px;
}

.scheduleContainer {
    overflow: none !important;
}

.scheduleHeader {
    display: block;
    overflow-y: scroll !important;
}

.scheduleBody {
    height: 600px;
    display: block;
    overflow-y: scroll !important;
}

.smallText {
    font-size: 0.7rem !important;
}

.smallText td {
    height: 32px !important;
}

.scheduleHeader th {
    white-space: pre-wrap;
}

.smallText td:not(.gap),
.smallText th:not(.gap) {
    width: 4%;
    padding: 0px !important;
    // white-space: pre;
}

.numberCell {
    width: 4% !important;
    text-align: center !important;
}

.infoCell {
    width: 4% !important;
}

.isHidden {
    display: none;
}

.isVisible {
    display: table-row;
}

.sa_width_120 {
    width: 8% !important;
    // min-width: 120px !important;
}

.no_bm > div,
.no_bm > div > div {
    margin-bottom: 0px !important;
    display: inline-block;
}

.datePickerWithNav button {
    margin-top: -1px;
}

@media (max-width: 1220px) {
    .scheduleContainer,
    .tablePagination {
        display: none !important;
    }
    .viewToSmall {
        display: flex !important;
    }
}
