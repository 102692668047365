.sa-c-header__toggle {
    background: 0 0;
    float: left;
    height: 56px;
    line-height: inherit;
    pointer-events: auto;
    position: relative;
    width: 56px;
    z-index: 4;
}

.sa-c-navigation-toggle__item,
.sa-c-navigation-toggle__item::after,
.sa-c-navigation-toggle__item::before {
    background: #0c0c0c;
    content: ' ';
    height: 3px;
    left: 50%;
    margin-left: -12px;
    margin-top: -1.5px;
    position: absolute;
    top: 50%;
    transition: all .2s;
    width: 24px;
}

.sa-c-navigation-toggle__item::before {
    transform: translateY(-6px);
}

.sa-c-navigation-toggle__item::after {
    transform: translateY(6px);
}

.sa-c-header__toggle.is-open .sa-c-navigation-toggle__item {
    background: 0 0;
}

.sa-c-header__toggle.is-open .sa-c-navigation-toggle__item::before {
    /* background: 0 0; */
    transform: rotate(-45deg);
}

.sa-c-header__toggle.is-open .sa-c-navigation-toggle__item::after {
    /* background: 0 0; */
    transform: rotate(45deg);
}

.sa-c-navigation-toggle__item::before {
    transform: rotate(90deg);
    transform: translateY(-6px);
}

/* .sc-c-header__toggle.is-open .sa-c-navigation-toggle__item::before {
    transform: rotate(90deg);
} */

/* .sa-c-navigation-toggle__item::after {
    transform: translateY(6px);
} */

/* .sc-c-header__toggle.is-open .sa-c-navigation-toggle__item::after {
    transform: rotate(90deg);
} */