$backgroundColor: rgba(12,12,12,.7);

.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 56px;
    left: 0;
    background: rgba(12,12,12,.7);
    z-index: 100;
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.2s;
}

@media (max-width: 718px) {
    .my_overlay.backdrop {
        opacity: 1;
        transform: scale(1);
        transition: opacity 0.2s;
    }
}
