.unpad {
    padding-top: 0 !important;
}

.fill div:nth-child(3) {
    min-height: 10rem;
}

.fill2 div:nth-child(2) > div {
    min-height: 11.8rem;
}

