$currentColor: #4c4c4c;

.ln-c-header-body {
	margin-top: 0px !important;
}

.toolbar {
	top: 0;
	left: 0;
	width: 100%;
	background-color: #fff;
	background-image: repeating-linear-gradient(#fff 0, #fff 63px, #8e8e8e 63px, #8e8e8e 64px);
	z-index: 4 !important;
}

.toolbar__navigation {
	display: flex;
	height: 64px;
	align-items: center;
	padding: 0 1rem;
	line-height: 64px;
	margin-left: 0;
	background-color: #fff;
	background-image: repeating-linear-gradient(#fff 0, #fff 63px, #8e8e8e 63px, #8e8e8e 64px);
}

.toolbar__navigation.sa-u-hard-right {
	padding-right: 0 !important;
}

.toolbar__logo {
	margin-left: 1rem;
}

.toolbar__navigation {
	max-width: 100%;
	padding-left: 0;
	padding-right: 0;
	margin: 0px auto;
}

.toolbar h1 {
	color: #f06c00;
	margin-bottom: 0;
	padding-right: 1rem;
	position: relative;
	white-space: nowrap;
	z-index: 1;
	font-size: 2rem;
	font-family: MaryAnn, "Trebuchet MS", sans-serif;
	font-weight: 700;
	line-height: inherit;
}

.toolbar__logo a {
	color: white;
	text-decoration: none;
	font-size: 1.5rem;
}

.spacer {
	flex: 1;
}

.toolbar_navigation-items ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.toolbar_navigation-items.inline > ul {
	display: flex;
}

.toolbar_navigation-items ul {
	display: block;
}

.toolbar_navigation-items li {
	position: relative;
}

.toolbar_navigation-items li button {
	background: 0 0;
	line-height: inherit;
	padding-right: calc(1rem + 20px);
	position: relative;
}

.toolbar_navigation-items li button.menu {
	padding: 0 0.5rem !important;
}

.toolbar_navigation-items li button svg {
	display: inline-block;
	height: 20px;
	margin-left: 0.5rem;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 20px;
}


.toolbar_navigation-items li button.notifications svg {
	margin-left: 4px !important;
}

.toolbar_navigation-items li button.menu svg {
	height: 25px;
	margin-left: 0rem !important;
	position: initial;
	transform: initial;
	width: 25px;
}

.toolbar_navigation-items li button svg.spinIcon {
	transform: translateY(-50%);
	transition: all 0.2s;
}

.toolbar_navigation-items li button.is-open svg.spinIcon {
	transform: translateY(-50%) rotate(180deg);
	transition: all 0.2s;
}

.sa-c-navigation__dropdown {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	-webkit-clip-path: inset(50%);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
	background: #fff;
	left: 0;
	list-style: none;
	margin: 0;
	top: 100%;
	visibility: hidden;
	z-index: 3;
	min-width: 100%;
}
.toolbar_navigation-items li .is-open + .sa-c-navigation__dropdown {
	clip: auto;
	-webkit-clip-path: none;
	clip-path: none;
	height: auto;
	overflow: visible;
	position: static;
	white-space: normal;
	width: auto;
	visibility: visible;
}

.toolbar_navigation-items a {
	display: block;
	font-family: MaryAnn, "Trebuchet MS", sans-serif;
	font-weight: 500;
	position: relative;
	text-decoration: none;
	font-size: 1.25rem;
	padding: 0 0.5rem;
	color: currentColor;
}

.sa-c-navigation__dropdown-toggle {
	font-size: 1.125rem;
	color: currentColor;
	display: block;
	font-family: MaryAnn, "Trebuchet MS", sans-serif;
	font-weight: 500;
	position: relative;
	text-decoration: none;
	background: 0 0;
	line-height: inherit;
	padding-right: calc(1rem + 20px);
	min-width: 100%;
}

.sa-c-navigation__dropdown {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	-webkit-clip-path: inset(50%);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
	background: #fff;
	left: 0;
	list-style: none;
	margin: 0;
	top: 100%;
	visibility: hidden;
	z-index: 3;
}

.toolbar_navigation-items button:focus,
.sa-c-navigation__dropdown-toggle:hover,
.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
	color: #e55000;
	outline: 0;
}

.sa-c-navigation__dropdown-toggle::before,
.toolbar_navigation-items a::before {
	background: #f06c00;
	bottom: 1px;
	content: " ";
	left: 0;
	opacity: 0;
	position: absolute;
	transition: opacity 0.2s;
}

.sa-c-navigation__dropdown-toggle.is-active::before,
.sa-c-navigation__dropdown-toggle:focus::before,
.sa-c-navigation__dropdown-toggle:hover::before,
.sa-c-navigation__link.is-active::before,
.toolbar_navigation-items a:focus::before,
.toolbar_navigation-items a:hover::before {
	opacity: 1;
	background: #f06c00;
}

@media (max-width: 718px) {
	.has-overlay .toolbar {
		position: static;
		height: 100vh;
		overflow: auto;
		pointer-events: auto;
		z-index: 4;
	}
	.toolbar {
		background-image: none;
	}

	.toolbar__navigation {
		height: 56px;
	}

	.sa-c-navigation__dropdown-toggle::before,
	.sa-c-navigation__link::before {
		bottom: 2px;
		top: 2px;
		width: 4px;
	}

	.sa-c-navigation__dropdown-toggle.is-open::before {
		height: calc(100% - 2px);
	}

	.navigation-items-wrapper {
		display: inline-block !important;
		position: fixed;
		top: 56px;
		width: 380px;
		background: white;
		height: 100%;
		transform: translateX(-100%);
		z-index: 200;
	}
	.navigation-items-wrapper.open {
		transform: translateX(0);
		transition: transform 0.2s ease-out;
	}

	.toolbar_navigation-items {
		display: list-item !important;
	}

	.toolbar_navigation-items.inline ul {
		display: list-item;
	}

	.toolbar_navigation-items li button {
		text-align: left;
		padding: 0 3.5rem;
	}

	.toolbar_navigation-items a {
		padding: 0 1rem;
	}

	.sa-c-navigation__dropdown a {
		padding: 0 2.5rem;
	}

	.toolbar_navigation-items li.has_dropdown button {
		padding: 0 1.5rem;
	}

	.ln-u-margin-left.ln-c-icon {
		left: 0;
		width: 1.35em !important;
		margin-left: 1rem !important;
	}
	.toolbar__navigation {
		line-height: 56px;
		pointer-events: auto;
		text-indent: 0.5rem;
		background-color: #fff;
		background-image: repeating-linear-gradient(#fff 0, #fff 55px, #8e8e8e 55px, #8e8e8e 56px);
	}

	.toolbar h1 {
		font-size: 1.625rem;
	}
	.side-drawer a {
		padding: 1rem 1.5rem;
	}

	.toolbar_navigation-items li a::before {
		bottom: 2px;
		height: auto;
		width: 4px;
		top: 2px;
	}

	.sa-c-navigation__dropdown a::before {
		background: black;
		opacity: 1;
	}

	.toolbar_navigation-items li.has_dropdown a::before,
	.sa-c-navigation__dropdown li a::before {
		height: 100%;
		top: 0px;
	}
}

@media (min-width: 718px) {
	.navigation-items-wrapper {
		width: -webkit-fill-available;
		display: inline-flex;
	}

	.toolbar__toggle-button {
		display: none;
	}

	.toolbar__logo {
		margin-left: 0;
	}

	.toolbar_navigation-items li a {
		padding: 0 0.5rem;
	}

	.sa-c-navigation__dropdown-toggle::before,
	.toolbar_navigation-items button::before,
	.toolbar_navigation-items a::before {
		height: 4px;
		right: 0;
	}

	.sa-c-navigation__dropdown a::before {
		bottom: 2px;
		height: auto;
		width: 4px;
		top: 2px;
	}

	.toolbar__navigation {
		max-width: 93rem;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}

	.toolbar_navigation-items li + li {
		margin-left: 1rem;
	}

	.toolbar_navigation-items ul li {
		margin-left: 0;
	}

	.toolbar_navigation-items li .is-open + .sa-c-navigation__dropdown {
		position: absolute;
		transform: scale(1);
		white-space: nowrap;
	}

	.sa-c-navigation__dropdown {
		border: 1px solid #8e8e8e;
		border-top: 0;
		transform: scale(1, 0);
		transform-origin: 50% 0;
	}

	.sa-c-navigation__dropdown-toggle,
	.sa-c-navigation__link {
		font-size: 1.25rem;
		padding: 0 0.5rem;
	}
}

@media (min-width: 1200px) {
	.toolbar__title h1 {
		padding-right: 1.5rem;
	}
}
