.buttons {
    margin-top: 0.4rem;
    margin-left: 3rem;
}

.inline {
    display: inline-block;
}

.nomargin {
    margin-bottom: 0;
}