$ln-assets-url: "~luna-style";
$ln-fonts-url: "../../fonts/";
@import '@jsluna/style/scss/main';

*:focus,
*:focus ~ * {
	box-shadow: none !important;
	border-color: inherit !important;
}

.ln-o-container--xs.ln-o-container--soft {
	min-width: 780px !important;
}

.sa_align_center {
	text-align: center !important;
	padding-left: 0 !important;
}

.sa_align_center_p_5 {
	text-align: center !important;
	padding-left: 0.5rem !important;
}

.sa_align_center_p_7 {
	text-align: center !important;
	padding-left: 0.7rem !important;
}

.sa_field_heading {
	font-weight: bold !important;
	// font-family: MaryAnn, "Trebuchet MS", sans-serif;
}

.accordion {
	border-bottom: none !important;
}

.sa_c_accordion__body {
	border: none !important;
}

.sa_c_accordion__body > div:nth-child(2) {
	background-color: #f6f6f6 !important;
	color: #4c4c4c !important;
}

h3 {
	margin-bottom: 5px !important;
}

.sa_textarea textarea {
	border: none;
	resize: none;
	padding-left: 0;
	max-width: 18.5rem;
}

.sa_textarea textarea:hover {
	box-shadow: none !important;
}

.sa_wrapper {
	margin-left: 0 !important;
	padding-top: 1.5rem !important;
	padding-right: 1.5rem !important;
}

.sa_align_left {
	padding-left: 0.5rem !important;
	text-align: left !important;
}

.cbCenter div {
	display: block;
	text-align: center;
}

.phoneNumber {
	font-weight: bold;
	margin-right: 2em;
}

// .invalid div:first-child {
// 	margin-top: 31px;
// 	font-size: 11px;
// 	/* display: none; */
// 	position: absolute;
// 	z-index: 4;
// 	margin-left: 6px;
// }

.daysInput div,
.daysInput div input {
	text-align: center;
}

.daysInput > div > div > div {
	text-align: center;
	font-size: 1.2rem;
}

.invalid > div:before {
	height: 15px;
	line-height: 15px;
	width: 15px;
}

// .invalid input {
// 	padding: 0 !important;
// 	padding-bottom: 10px !important;
// 	// border: 1px solid #d70000 !important;
// 	// background-color: red !important;
// 	// background-image: none !important;
// }

.fixedHeader {
	padding-bottom: 0;
	width: 100%;
	z-index: 3;
	box-shadow: $ln-color-dark 0px 4px 2px -2px;
}

.fixedHeader.stick:before {
	content: "";
	height: 65px;
	margin-top: -65px;
	width: 100%;
	background-color: #f6f6f6;
	display: block;
}

:global .ln-o-section.detailsSection {
	margin-top: 0px;
}

:global .ln-o-section.detailsSection.fixedHeader {
	margin-top: 165px;
}
