@import '@jsluna/style/scss/foundation.scss';

.container {
    background-color: $ln-color-white;
}

.icon {
    height: 2.5em !important;
    width: 2.5em !important;
}

h5, h6 {
    display: inline-block;
    margin-left: 1rem !important;
}

h6 {
    margin-bottom: 0 !important;
}

.menuContainer {
    border-right: 1px solid $ln-color-grey;
    margin-left: 1.5rem;
    height: 75vh;
}

.settingsIcon {
    padding: 1rem 0.25rem;
}

.menuItem {
    color: $ln-color-orange;
}

.menuItem:hover {
    cursor: pointer;
}

.disabled {
    color: $ln-color-grey-light;
}

.disabled:hover {
    cursor: auto;
}