.ln-c-table-container {
	overflow: inherit !important;
}

.schedulesNav {
	min-width: 1110px;
	margin: 0 0 10px;
}

.schedulesNav .weekNumberLi {
	margin-top: 1px !important;
	margin-left: -1px;
	margin-right: 1px;
}
