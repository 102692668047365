@import '@jsluna/style/scss/foundation.scss';
:root {
    --selected-color: rgb(220, 255, 255);
    --expanded-color: rgb(220, 255, 255);
    --edit-color: $ln-color-red;
    --inactive-first-color: rgb(150, 150, 150);
    --inactive-color: rgb(180, 180, 180);
    --inactive-selected-color: rgb(150, 150, 150);
    --dark-border: #4c4c4c;
    --row-background: white;
    --light-border: #c8c8c8;
    --selected-foreground: #ffffff;
    --hover-foreground: #000000;
}

/* Column alignment adjustments VIEW SPECIFIC */

.lunaDataTableStyle :global .p-datatable-thead tr th:nth-child(1) span,
.lunaDataTableStyle :global .p-datatable-thead tr th:nth-child(4) span {
    padding-left: 0px !important;
    text-align: left !important;
}

.lunaDataTableStyle :global .p-datatable-thead tr th:nth-child(1),
.lunaDataTableStyle :global .p-datatable-thead tr th:nth-child(4) {
    text-align: left !important;
}

.lunaDataTableStyle :global .p-datatable-tbody tr td:nth-child(1),
.lunaDataTableStyle :global .p-datatable-tbody tr td:nth-child(4) {
    text-align: left !important;
    padding-left: 12px !important;
}
/* End of view specific */

.lunaDataTableStyle table {
    border-spacing: 0 5px !important;
    border-collapse: separate !important;
    padding: 5px !important;
}

.lunaDataTableStyle table tbody tr {
    min-height: 55px !important;
    background-color: var(--row-background) !important;
    color: black !important;
    border: 1px solid #4c4c4c !important;
}

.lunaDataTableStyle table tbody tr td {
    border: 1px solid #4c4c4c !important;
    padding: 5px !important;
}

.lunaDataTableStyle table tbody tr:hover {
    cursor: pointer !important;
    background: $ln-color-orange-light !important;
    color: var(--hover-foreground) !important;
}

.lunaDataTableStyle :global .p-datatable-tbody tr.p-highlight {
    color: var(--selected-foreground) !important;
    background: $ln-color-orange !important;
}

.lunaDataTableStyle table tbody tr.selectedItem,
.lunaDataTableStyle table tbody tr.expandedItem {
    background-color: $ln-color-orange-light !important;
    color: var(--selected-foreground) !important;
}

.lunaDataTableStyle table tbody tr.editItem {
    background-color: $ln-color-blue !important;
    color: var(--selected-foreground) !important;
}

body :global .p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.lunaDataTableStyle :global .p-datatable-tbody tr.selectedItem .p-row-toggler,
.lunaDataTableStyle :global .p-datatable-tbody tr.expandedItem .p-row-toggler,
.lunaDataTableStyle :global .p-datatable-tbody tr.editItem .p-row-toggler,
.lunaDataTableStyle :global .p-datatable-tbody tr.p-highlight .p-row-toggler {
    color: black !important;
}
.lunaDataTableStyle table tbody tr.inactiveItem {
    background-color: var(--inactive-color) !important;
}

.lunaDataTableStyle table tbody tr.selectedItem td,
.lunaDataTableStyle table tbody tr.editItem td,
.lunaDataTableStyle table tbody tr.expandedItem td {
    border-color: var(--dark-border) !important;
}
.lunaDataTableStyle :global .p-datatable-row td {
    border: 1px solid var(--dark-border) !important;
    padding: 0 3px !important;
}

.lunaDataTableStyle table tbody tr.inactiveItem td:first-child {
    background-color: var(--inactive-first-color) !important;
}
.lunaDataTableStyle table tbody tr.inactiveItem.selectedItem td {
    background-color: var(--inactive-selected-color) !important;
}

.lunaDataTableStyle :global .p-datatable-tbody tr td:not(:first-child) {
    border-left: none !important;
}

.lunaDataTableStyle :global .p-datatable-tbody tr td:not(:last-child) {
    border-right: none !important;
}

.lunaDataTableStyle :global .p-datatable-tbody tr:not(.p-datatable-row) td {
    border: 1px solid var(--dark-border) !important;
    border-top: 0px !important;
    background-color: var(--expanded-color);
    transition: background-color 0.5s ease;
    -webkit-transition: background-color 0.5s ease;
}

.lunaDataTableStyle :global .p-datatable-tbody tr:not(.p-datatable-row) td:first-child:before {
    content: '';
    position: absolute;
    height: 10px;
    left: 0;
    margin: -14px 21px 0 21px;
    background-color: var(--selected-color);
    border-color: var(--dark-border) !important;
    width: -webkit-fill-available;
    border-left: 1px solid;
    border-right: 1px solid;
}

.lunaDataTableStyle :global .p-datatable-row td input {
    border-radius: 3px solid var(--dark-border) !important;
    background-color: white;
    text-align: center;
    font-family: sans-serif;
}

.lunaDataTableStyle :global .p-datatable-thead,
.lunaDataTableStyle :global .p-datatable-tfoot {
    font-size: 0.8rem;
}

.lunaDataTableStyle :global .p-datatable-tbody {
    font-size: 0.8rem;
}

.lunaDataTableStyle :global .p-datatable-row {
    font-family: sans-serif !important;
}

.lunaDataTableStyle :global .p-datatable-header {
    position: absolute;
    top: -57px;
    right: 0;
    background-color: white !important;
    float: right;
    border: none !important;
    padding: 0 !important;
}

.lunaDataTableStyle :global .p-multiselect-label {
    line-height: 44px;
    padding: 0px 5px !important;
}

.lunaDataTableStyle :global .p-multiselect {
    border: 2px solid $ln-color-orange;
    float: right;
    height: 48px;
}
.lunaDataTableStyle :global .p-multiselect:hover {
    border-color: $ln-color-orange !important;
}

.lunaDataTableStyle :global .p-multiselect .p-multiselect-trigger {
    background-color: transparent !important;
}

.lunaDataTableStyle :global .p-datatable-scrollable-body-table {
    background-color: $ln-color-light !important;
}

.lunaDataTableStyle :global .p-datatable-scrollable-header,
.lunaDataTableStyle :global .p-datatable-scrollable-footer {
    background-color: white !important;
}
.lunaDataTableStyle :global .p-datatable-scrollable-header {
    height: 95px;
    border-top: 2px solid #737373 !important;
    border-bottom: 2px solid #737373 !important;
}
.lunaDataTableStyle :global .p-datatable-scrollable-body table {
    border-collapse: separate !important;
    border-spacing: 0 8px !important;
    margin-bottom: 0;
    padding-left: 8px;
}

.lunaDataTableStyle :global .p-datatable-scrollable-footer {
    border-top: 2px solid #404040 !important;
    height: 10px;
}

.lunaDataTableStyle :global .p-datatable-footer {
    border-top: 1px solid var(--dark-border) !important;
}

.largeModal > div {
    max-width: 1000px;
}

.largeModal :global .DateInput {
    width: inherit !important;
}

th.nofilter {
    padding-bottom: 3rem !important;
}

.nofilter {
    text-align: center;
}
