.ln-c-accordion.is-open .ln-c-accordion__head {
    background-color: #f06c00 !important;
}

.ln-c-accordion.is-open .ln-c-accordion__head .ln-c-accordion__title {
    color: white !important;
}

.ln-c-accordion .ln-c-accordion__head .ln-c-accordion__title {
    pointer-events: none !important;
}

.not-checked .ln-c-form-option--switch .ln-c-form-option__input ~ .ln-c-form-option__label::before {
    background-color: white !important;
    border-color: #8e8e8e !important;
    border-width: 1px !important;
}

.not-checked .ln-c-form-option--switch .ln-c-form-option__input ~ .ln-c-form-option__label:hover::before {
    border-width: 2px !important;
    border-color: #4c4c4c !important;
}

.checked .ln-c-form-option--switch .ln-c-form-option__input ~ .ln-c-form-option__label::before {
    background-color: #f06c00 !important;
    border-color: #f06c00 !important;
}

.not-checked .ln-c-form-option--switch .ln-c-form-option__input ~ .ln-c-form-option__label::after {
    transform: translateX(0px) !important;
    border-width: 1px !important;
    border-color: #8e8e8e !important;
}

.not-checked .ln-c-form-option--switch .ln-c-form-option__input ~ .ln-c-form-option__label:hover::after {
    border-width: 2px !important;
    border-color: #4c4c4c !important;
}

.checked .ln-c-form-option--switch .ln-c-form-option__input ~ .ln-c-form-option__label::after {
    transform: translateX(16px) !important;
    border-width: 2px !important;
    border-color: #f06c00 !important;
}

.ln-c-accordion .ln-c-accordion__head button {
    font-size: 1.5rem;
}

.ln-c-accordion.is-open .ln-c-accordion__head button {
    color: white !important;
}

// .time div {
// 	font-size: 1rem !important;
// }

// // .time input {
// // 	text-align: center;
// // }

.dcCVLDaytrue {
    border-top: 1px solid #8e8e8e;
    background-color: #d7d7d7 !important;
}

.dcPickTimefalse,
.dcXdocArrivalTimefalse {
    background-color: white !important;
    border-bottom: 1px solid #8e8e8e;
}

.time div.ln-c-field-info {
    font-size: 0.7rem !important;
}

@media (min-width: 720px) {
    .ln-o-container--xs.ln-o-container--soft {
        max-width: 67rem !important;
    }
}

@media (max-width: 1111px) {
    .ln-c-accordion__body .weekNumberLi {
        display: none;
    }
    .ln-o-container--xs.ln-o-container--soft {
        max-width: 53rem !important;
    }
}
