/* Argos PrimeReact theme based on the Omega theme */

/* Modified by A Oakley - 2019-07-08 */

@charset "UTF-8";
.pi {
	font-family: "primeicons" !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	display: inline-block;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* DATA TABLE */

body .p-datatable-row {
	font-family: Arial, sans-serif;
}

body .p-datatable .p-datatable-tbody > tr {
	height: 48px !important;
}

body .p-datatable .p-datatable-tbody > tr > td {
	border: none;
}

body .p-datatable .p-datatable-tbody > tr:nth-child(even) {
	background-color: #f6f6f6;
}

body .p-datatable .p-datatable-thead > tr > th {
	border: none;
	background-color: white;
}

body .p-datatable .p-sortable-column:not(.p-highlight):hover {
	background-color: white;
	color: black;
}

body .p-datatable .p-sortable-column.p-highlight {
	background-color: white;
	color: black;
}

body .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
	color: #848484;
}

body .ui-widget,
body .p-component {
	font-family: MaryAnn, "Trebuchet MS", sans-serif;
	font-size: 1em;
}

body .p-inputtext {
	border-radius: 2px;
}

/* haven't figured out how to get this icon in place without screwing up the input box height
    the icon is a base64 encoded version of the Sainsbury's SVG
    do we even need this icon displayed? If not, delete this */

/* .p-column-filter {
    background: white url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGFyaWEtaGlkZGVuPSJ0cnVlIiBjbGFzcz0ibG4tYy1pbnB1dC1ncm91cF9faWNvbiBsbi1jLWljb24iIHJvbGU9ImltZyIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNOS42OTIgMTUuMzMzYTUuNjQxIDUuNjQxIDAgMSAwIDAtMTEuMjgyIDUuNjQxIDUuNjQxIDAgMCAwIDAgMTEuMjgyem02LjExNy0uOTc1bDUuODkgNS44OTFhMS4wMjYgMS4wMjYgMCAwIDEtMS40NSAxLjQ1bC01Ljg5LTUuODlhNy42OTIgNy42OTIgMCAxIDEgMS40NS0xLjQ1eiIgZmlsbD0iY3VycmVudENvbG9yIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=') !important;
    background-repeat: no-repeat !important;
    padding-left: 25px !important;
    color: #0c0c0c !important;
    height: 1.5rem !important;
} */

.p-datatable {
	background-color: white;
}

.p-datatable-wrapper {
	padding: 1rem;
}

body .p-paginator .p-paginator-pages .p-paginator-page.p-highlight,
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group.p-highlight {
	background-color: #4c4c4c;
	color: #ffffff;
}

body .p-paginator {
	background-color: white;
	border: none;
	padding: 0;
}

body .p-paginator .p-paginator-pages .p-paginator-page {
	color: #4c4c4c;
}

/* FILE UPLOADER */

body .p-fileupload .p-fileupload-buttonbar {
	border-radius: 2px;
}

.p-fileupload-content.p-fileupload-highlight {
	border-color: #f06c00;
}

body .p-progressbar .p-progressbar-value {
	border: 0 none;
	margin: 0;
	background: #f06c00;
}

body .p-button {
	background-color: white;
	border-radius: 2px;
	border: 2px solid #f06c00;
	color: #f06c00;
	font-size: 1.125rem;
	font-weight: 700;
	min-height: 48px;
	min-width: 49px;
}

body .p-button:enabled:hover {
	border-color: #e55000;
	background-color: rgba(229, 80, 0, 0.08);
	color: #e55000;
}

/* tooltip overrides */
body .p-tooltip-text {
	background-color: #e55000 !important;
	color: #ffffff !important;
	padding: 0.429em;
	box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.5);
	border-radius: 2px !important;
}
body .p-tooltip.p-tooltip-top {
	margin-top: -3px;
}
body .p-tooltip.p-tooltip-right {
	margin-left: 3px;
}
body .p-tooltip.p-tooltip-bottom {
	margin-top: 3px;
}
body .p-tooltip.p-tooltip-left {
	margin-left: -3px;
}
body .p-tooltip .p-tooltip-arrow {
	border-width: 6px;
	margin-top: -6px;
	margin-left: -6px;
}
body .p-tooltip.p-tooltip-right .p-tooltip-arrow,
body .p-tooltip.p-tooltip-left .p-tooltip-arrow {
	top: 50%;
}
body .p-tooltip.p-tooltip-top .p-tooltip-arrow,
body .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
	left: 50%;
}
body .p-tooltip.p-tooltip-top .p-tooltip-arrow {
	border-top-color: #e55000;
	bottom: -8px;
}
body .p-tooltip.p-tooltip-right .p-tooltip-arrow {
	border-right-color: #e55000;
	left: -2px;
}
body .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
	border-bottom-color: #e55000;
	top: -2px;
}
body .p-tooltip.p-tooltip-left .p-tooltip-arrow {
	border-left-color: #e55000;
	right: -8px;
}
body .p-tooltip .p-tooltip-text {
	padding: 0.5rem 1rem;
	background-color: #e55000 !important;
	color: #fff !important;
	white-space: pre-line;
}

/* Panel */
body .p-panel-title {
	color: #f06c00;
}

body .p-panel-titlebar {
	border-top-left-radius: 2px !important;
	border-top-right-radius: 2px !important;
}

body .p-panel-content {
	border-bottom-left-radius: 2px !important;
	border-bottom-right-radius: 2px !important;
}
