.Input {
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
}

.Label {
	font-weight: bold;
	display: block;
	margin-bottom: 8px;
}

.InputElement {
	outline: none;
	border: 1px solid #ccc;
	background-color: white;
	font: inherit;
	padding: 6px 10px;
	display: block;
	width: 100%;
	box-sizing: border-box;
}

.InputElement:focus {
	outline: none;
	background-color: #ccc;
}

.Invalid {
	border: 1px solid red;
	background-color: #fda49a;
}

.accordion .ValidationError {
	font-size: 12px;
}

.ValidationError {
	color: red;
	margin: 5px 0;
	font-size: 14px;
}
