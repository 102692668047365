body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: #f06c00 !important;
    transition-duration: 0.2s;
}

li a {
    color: #4c4c4c !important;
}

a h5 {
    color: #f06c00 !important;
    margin-left: 0px !important;
    text-align: center;
    display: block;
}

a p {
    color: rgb(76, 76, 76) !important;
}

a > div {
    transition-duration: 0.2s;
}

a:hover > div {
    background-color: rgba(229, 80, 0, 0.08) !important;
    box-shadow: 0 0 0 3px transparent;
    transition-duration: 0.2s;
    transition-property: box-shadow, color, background-color;
    transition-timing-function: ease-out;
    border: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* .ln-c-table-container {
    max-height: 550px !important;
    height: 550px !important;
    overflow: scroll;
}

.ln-c-table__cell, .ln-c-table__header-cell {
    padding: 0.2rem 0.2rem !important;
} */

/* Scrollbar */
::-webkit-scrollbar {
    width: 15px;
}
/* Track */
::-webkit-scrollbar-track {
    max-width: 15px !important;
    background: #fff;
    /* border-right: 1px solid rgb(87,87,87); */
}
/* Handle */
::-webkit-scrollbar-thumb {
    /* -webkit-border-radius: 14px;
    border-radius: 14px; */
    background: #8e8e8e;
    /* border: 1px solid rgb(87,87,87) !important; */
}
