.noWrap {
	white-space: nowrap;
	padding: 0.5rem 0 !important;
}

.noWrap fieldset {
	margin-bottom: 0;
}

.scheduleContainer {
	max-height: 440px;
}

.scheduleTableContainer {
	min-width: 1000px;
}

.scheduleTableContainer td:not(:nth-child(2)),
.scheduleTableContainer th:not(:nth-child(2)) {
	padding: 0.2rem 0.5rem !important;
	text-align: center !important;
}

.scheduleTableContainer th:nth-child(2),
.scheduleTableContainer td:nth-child(2) {
	white-space: nowrap;
	width: 180px;
}

.scheduleTableContainer div {
	margin-bottom: 0 !important;
}

.scheduleTableContainer td {
	transition: height 0.05s ease-in, padding-top 0.03s ease-in, padding-bottom 0.03s ease-in;
}

.scheduleTableContainer .detailRow th,
.scheduleTableContainer .detailRow td {
	line-height: 0;
	height: 0;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	overflow: hidden;
	opacity: 0;
	transition: height 0.05s ease-out, padding-top 0.03s ease-out, padding-bottom 0.03s ease-out;
}
.scheduleTableContainer .detailRow td button {
	display: none;
}

.detailRow div {
	height: 0px;
	overflow: hidden;
}

.scheduleTableContainer th input,
.scheduleTableContainer td input {
	padding: 0.2rem 0.2rem;
	text-align: center !important;
	font-weight: bold;
}

svg.spinIcon {
	transform: translateY(-50%);
	transition: all 0.2s;
	margin-top: 1rem;
}

svg.spinIcon:hover {
	color: #e55000;
	cursor: pointer;
}

.isOpen svg.spinIcon {
	transform: translateY(-50%) rotate(90deg);
	transition: all 0.2s;
}

.noDelivery,
.noDelivery input {
	background-color: #e6e6e6 !important;
	background-image: none !important;
}

.invalid div:first-child {
	margin-top: 31px;
	font-size: 11px;
	/* display: none; */
	position: absolute;
	z-index: 4;
	margin-left: 6px;
}

.invalid > div:before {
	height: 12px;
	line-height: 12px;
	width: 12px;
}

.invalid input {
	padding: 0 !important;
	padding-bottom: 10px !important;
	// border: 1px solid #d70000 !important;
	// background-color: red !important;
	// background-image: none !important;
}

.codeList td,
.codeList th {
	text-align: center !important;
}

.width {
	min-width: 94px;
	max-width: 94px;
}

.scheduleOptionList ul {
	height: 394px;
	overflow-y: scroll;
	position: inherit;
}

.scheduleHeader li > span {
	padding: 0px 0.5rem;
}

input:disabled,
select:disabled {
	// color: #8e8e8e;
	opacity: 0.7 !important;
	font-weight: normal !important;
}

input:disabled ~ label,
select:disabled ~ label {
	// color: #8e8e8e;
	opacity: 0.7 !important;
	font-weight: normal !important;
}

.labelView :disabled {
	font-weight: bold;
	font-style: normal;
	font-size: 15px;
	opacity: 1 !important;
	// border: none;
	border-color: #e55000;
	font-weight: bold !important;
	background-image: none;
	background-color: rgba(229, 80, 0, 0.08);
}

.labelView input:disabled {
	text-align: center;
	color: #4c4c4c;
}

.labelView textarea:disabled {
	min-height: inherit;
	height: 48px;
	padding-top: 1px;
	padding-bottom: 1px;
	resize: none;
	overflow: hidden;
}

.labelView {
	max-width: 360px;
}
.labelView div {
	border: none;
	background: none !important;
}

.labelView.btn {
	position: absolute;
	top: 45px;
	right: 10px;
}

.labelView textarea {
	min-width: 250px;
}

.scheduleHeader li button {
	margin-top: 31px;
}

.btn {
	margin: 0 7px;
}

.btn:not(.active) {
	pointer-events: none;
}

.btn.active:hover {
	cursor: pointer;
	pointer-events: initial;
}

// .labelView.btn.disabled:hover {
// 	cursor: not-allowed !important;
// }

.formScheduleName {
	font-size: 1.1rem;
	width: calc(100% - 16px);
	margin-left: -50% !important;
	position: absolute;
	left: 50%;
	text-align: center;
	pointer-events: none;
}

@media (min-width: 720px) {
	.scheduleOptionList ul {
		margin-bottom: -1.5rem !important;
	}
}

@media (max-width: 1280px) {
	.scheduleHeader > li:last-child {
		display: none !important;
	}
}

@media (min-width: 1470px) {
	.scheduleHeader > li:last-child {
		position: absolute;
		right: 0;
	}
}
